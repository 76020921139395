<template>
  <div class="page_container">
    <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="news_content container">
            <!-- <div class="content_title">
                秉匠科技又双叒叕获奖了！
            </div>
            <div class="content_time">
                发布时间：2020-10-27 17:20
            </div>
            <div class="content_report">
                <div class="content_report_text">
               10月27日，在2020年第三届浦东新区BIM技术应用创新劳动和技能竞赛暨长三角区域邀请赛表彰大会上，秉匠科技的“黑洞”图形引擎荣获最佳图形引擎奖一等奖。
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_pudong01.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <p>
                        本次比赛以“建功新时代，当好主力军”为主题，得到了上海市、浦东新区相关委办局和单位的高度重视。经过激烈角逐和严格评审，最终评选出一批优秀作品和专业人才。
                    </p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_pudong02.jpg" alt="">
                </div>
                <div class="content_report_text">
                    <p>
                      “黑洞”图形引擎（BlackHole-Engine）是秉匠科技完全自主研发、具备国际领先水平的BIM+GIS轻量化渲染引擎。主要为大型土木工程参建各方提供Web端基于多源异构BIM模型的数据可视化承载平台，解决设计、建造、运维过程中的可视化沟通共享问题。可以为城市CIM应用提供底层核心数据支持。
                    </p>
                </div>
                <div class="content_report_picture">
                    <img src="../../assets/image/news/new_pudong03.jpg" alt="">
                </div>
            </div> -->
        <div class="left">
          <div class="tit">
            <h1>秉匠科技又双叒叕获奖了！</h1>
            <div class="time">2020-10-27 17:20</div>
            <div class="info">
              <p>10月27日，在2020年第三届浦东新区BIM技术应用创新劳动和技能竞赛暨长三角区域邀请赛表彰大会上，秉匠科技的“黑洞”图形引擎荣获最佳图形引擎奖一等奖。</p>
              <p class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_pudong01.jpg"></span>
              </p>
              <p>本次比赛以“建功新时代，当好主力军”为主题，得到了上海市、浦东新区相关委办局和单位的高度重视。经过激烈角逐和严格评审，最终评选出一批优秀作品和专业人才。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_pudong02.jpg"></span>
              </p>
              <p> “黑洞”图形引擎（BlackHole-Engine）是秉匠科技完全自主研发、具备国际领先水平的BIM+GIS轻量化渲染引擎。主要为大型土木工程参建各方提供Web端基于多源异构BIM模型的数据可视化承载平台，解决设计、建造、运维过程中的可视化沟通共享问题。可以为城市CIM应用提供底层核心数据支持。</p>
              <p  class="p_pic">
                <span style="font-size: 14px;"><img src="../../assets/image/news/new_pudong03.jpg"></span>
              </p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="tit"><h3><font></font>热点内容</h3></div>
          <ul>
            <li v-for="item in hotList" :key="item.id">
              <p><router-link :to="item.link">{{item.name}}</router-link></p>
              <span>{{item.time}}</span>
            </li>
          </ul>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      hotList: [
        {id:1,name: '“黑洞”图形引擎助力中电建华东院打造智慧城市CIM平台',time: '2020-11-09',link:'/newsDetails_new_CIM'},
        {id:2,name: '秉匠科技又双叒叕获奖了！',time: '2020-10-28',link:'/newsDetails_new_BIM'},
        {id:3,name: '江苏东交智控科技集团股份有限公司与秉匠科技签订“黑洞”图形引擎采购合同',time: '2020-11-02',link:'/newsDetails_new_dongjiao'},
        {id:4,name: '“黑洞引擎”荣获“上海市第二届BIM技术应用创新大赛”特别创意优秀奖',time: '2020-8-10',link:'/newsDetails_new_hj'}
      ]
    }
  },
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.page_container{
  margin-top: 50px;
  .news_content{
    overflow: hidden;
    .left{
      width: 60%;
      height: 100%;
      .tit{
        h1{
          color: #222;
          font-size: 22px;
          font-weight: normal;
          line-height: 30px;
        }
        .time{
          margin: 10px;
          height: 27px;
          border-bottom: 1px #ddd solid;
          font-size: 14px;
          color: #999;
          padding-right: 30px;
        }
      }
      .info{
        padding-top: 32px;
        text-align: center;
        p{
          font-size: 16px;
          color: #222;
          line-height: 28px;
          text-align: left;
          text-indent:2em;
        }
        .p_pic{
          text-indent: 0;
        }
        img{
          margin: 16px 0;
        }
      }
    }
    .right{
      width: 35%;
      height: 100%;
      .tit{
        border-bottom: 1px #444 solid;
        padding-bottom: 14px;
        margin-top: 24px;
        font{
          width: 4px;
          height: 18px;
          background: #222;
          display: inline-block;
          margin-right: 16px;
          position: relative;
          top: 4px;
        }
      }
      ul{
        padding: 12px;
        li{
          line-height: 24px;
          font-size: 16px;
          color: #555;
          border-bottom: 1px #ddd solid;
          padding: 12px 0;
          p{
            a{
              color: #555;
            }
          }
          span{
            display: block;
            font-size: 14px;
            color: #777;
            padding-top: 4px;
          }
        }
        .router-link-active{
          border-bottom: none;
        }
      }
    }
  }
}
</style>
